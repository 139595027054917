import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NeighborhoodItem, NeighborhoodState } from "../types";

const initialState: NeighborhoodState = {
  dataNeighborhood: [],
  loading: true,
  localityId: "",
  error: ""
};

const neighborhoodSlice = createSlice({
  name: "neighborhood",
  initialState,
  reducers: {
    loadNeighborhood: () => initialState,
    loadNeighborhoodByLocality: (
      state,
      {payload}: PayloadAction<string>
    )=> ({
      ...state,
      localityId: payload
    }),
    loadNeighborhoodSuccess: (
      state,
      { payload }: PayloadAction<NeighborhoodItem[]>
    ) => ({
      ...state,
      dataNeighborhood: payload,
      loading: false,
    }),
    loadNeighborhoodError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { 
  loadNeighborhood, 
  loadNeighborhoodByLocality,
  loadNeighborhoodSuccess, 
  loadNeighborhoodError 
} = neighborhoodSlice.actions;

export default neighborhoodSlice.reducer;
