import { combineReducers, Reducer } from "redux";
import { RootState } from "@lib/store/types";
import typedocumentsReducer from "@lib/store/slices/typeDocuments";
import genderReducer from "@lib/store/slices/gender";
import civilStatusReducer from "@lib/store/slices/civilStatus";
import mainRoadReducer from "@lib/store/slices/mainRoad";
import mainRoadLetterReducer from "@lib/store/slices/mainRoadLetter";
import citySectorReducer from "@lib/store/slices/citySector";
import postulationReducer from "@lib/store/slices/postulation";
import postulationModeReducer from "@lib/store/slices/postulationMode";
import pensionReducer from "@lib/store/slices/Pension";
import reasonNotPaymentPensionReducer from "@lib/store/slices/reasonNotPaymentPension";
import authReducer from "@lib/store/slices/auth";
import affiliationCompensationFundReducer from "@lib/store/slices/affiliationCompensationFund";
import earnedSalariesReducer from "@lib/store/slices/earnedSalaries";
import educationalLevelReducer from "@lib/store/slices/educationalLevel";
import epsReducer from "@lib/store/slices/eps";
import ethnicGroupReducer from "@lib/store/slices/ethnicGroup";
import pensionsFundReducer from "@lib/store/slices/pensionsFund";
import populationTypeReducer from "@lib/store/slices/populationType";
import TypeVincuCompensationFundReducer from "@lib/store/slices/TypeVincuCompensationFund";
import vulnerabilityFactorReducer from "@lib/store/slices/vulnerabilityFactor";
import residentialTypeReducer from "@lib/store/slices/residentialType";
import formationReducer from "@lib/store/slices/formation";
import vulnerabilityConditionReducer from "@lib/store/slices/vulnerabilityCondition";
import disabilityTypeReducer from "@lib/store/slices/disabilityType";
import localityReducer from "@lib/store/slices/locality";
import educationalLevelFomentoReducer from "@lib/store/slices/educationalLevelFomento";
import scheduleAssignmentsReducer from "@lib/store/slices/scheduleAssignments";
import unemployedReducer from "@lib/store/slices/unemployed";
import typedocumentsFomentoReducer from "@lib/store/slices/typeDocumentsFomento";
import genderFomentoReducer from "@lib/store/slices/genderFomento";
import indigenousReservationReducer from "@lib/store/slices/indigenousReservation";
import indigenousVillageReducer from "@lib/store/slices/indigenousVillage";
import receptionCenterReducer from "@lib/store/slices/receptionCenter";
import ethnicGroupFomentoReducer from "@lib/store/slices/ethnicGroupFomento";
import capturingLayoffsEntityReducer from "@lib/store/slices/capturingLayoffsEntity";
import documentationReducer from "@lib/store/slices/documentation";
import validationReducer from "@lib/store/slices/validation";
import postulationBusinessDaysReducer from "@lib/store/slices/postulationBusinessDays";
import navigationReducer from "@lib/store/slices/navigation";
import offDaysReducer from "@lib/store/slices/offDays";
import preValidator from "./preValidator";
import neighborhoodReducer from '@lib/store/slices/neighborhood'

const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  typeDocumentsState: typedocumentsReducer,
  genderState: genderReducer,
  civilStatusState: civilStatusReducer,
  mainRoadState: mainRoadReducer,
  mainRoadLetterState: mainRoadLetterReducer,
  citySectorState: citySectorReducer,
  postulationState: postulationReducer,
  postulationModeState: postulationModeReducer,
  pensionState: pensionReducer,
  reasonNotPaymentPensionState: reasonNotPaymentPensionReducer,
  authState: authReducer,
  affiliationCompensationFundState: affiliationCompensationFundReducer,
  educationalLevelState: educationalLevelReducer,
  earnedSalariesState: earnedSalariesReducer,
  TypeVincuCompensationFundState: TypeVincuCompensationFundReducer,
  ethnicGroupState: ethnicGroupReducer,
  epsState: epsReducer,
  populationTypeState: populationTypeReducer,
  vulnerabilityFactorState: vulnerabilityFactorReducer,
  PensionsFundState: pensionsFundReducer,
  residentialTypeState: residentialTypeReducer,
  formationState: formationReducer,
  vulnerabilityConditionState: vulnerabilityConditionReducer,
  localityState: localityReducer,
  disabilityTypeState: disabilityTypeReducer,
  educationalLevelFomentoState: educationalLevelFomentoReducer,
  scheduleAssignmentsState: scheduleAssignmentsReducer,
  unemplotedState: unemployedReducer,
  typeDocumentsFomentoState: typedocumentsFomentoReducer,
  genderFomentoState: genderFomentoReducer,
  indigenousReservationState: indigenousReservationReducer,
  indigenousVillageState: indigenousVillageReducer,
  receptionCenterState: receptionCenterReducer,
  ethnicGroupFomentoState: ethnicGroupFomentoReducer,
  capturingLayoffsState: capturingLayoffsEntityReducer,
  documentationState: documentationReducer,
  validationState: validationReducer,
  postulationBusinessDaysState: postulationBusinessDaysReducer,
  navigationState: navigationReducer,
  offDaysState: offDaysReducer,
  preValidatorState: preValidator,
  neighborhoodState: neighborhoodReducer,
});

export default rootReducer;
