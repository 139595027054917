import { RootState } from "@lib/store/types";

export const getAuth = (state: RootState) => state.authState;
export const getTypeDocuments = (state: RootState) => state.typeDocumentsState;
export const getGenders = (state: RootState) => state.genderState;
export const getCivilStatus = (state: RootState) => state.civilStatusState;
export const getMainRoad = (state: RootState) => state.mainRoadState;
export const getMainRoadLetter = (state: RootState) =>
  state.mainRoadLetterState;
export const getCitySector = (state: RootState) => state.citySectorState;
export const getPostulation = (state: RootState) => state.postulationState;
export const getPostulationMode = (state: RootState) =>
  state.postulationModeState;
export const getPension = (state: RootState) => state.pensionState;
export const getReasonNotPaymentPension = (state: RootState) =>
  state.reasonNotPaymentPensionState;
export const getPensionsFund = (state: RootState) => state.PensionsFundState;
export const getEducationalLevel = (state: RootState) =>
  state.educationalLevelState;
export const getEarnedSalaries = (state: RootState) =>
  state.earnedSalariesState;

export const getAffiliationCompensationFund = (state: RootState) =>
  state.affiliationCompensationFundState;
export const getTypeVincuCompensationFund = (state: RootState) =>
  state.TypeVincuCompensationFundState;

export const getEthnicGroup = (state: RootState) => state.ethnicGroupState;
export const getEps = (state: RootState) => state.epsState;
export const getPopulationType = (state: RootState) =>
  state.populationTypeState;
export const getVulnerabilityFactor = (state: RootState) =>
  state.vulnerabilityFactorState;
export const getResidentialType = (state: RootState) =>
  state.residentialTypeState;
export const getFormation = (state: RootState) => state.formationState;

export const getLocality = (state: RootState) => state.localityState;

export const getDisabilityType = (state: RootState) =>
  state.disabilityTypeState;
export const getVulnerabilityCondition = (state: RootState) =>
  state.vulnerabilityConditionState;

export const getEducationalLevelFomento = (state: RootState) =>
  state.educationalLevelFomentoState;

export const getScheduleAssignments = (state: RootState) =>
  state.scheduleAssignmentsState;

export const getUnemployed = (state: RootState) => state.unemplotedState;

export const getTypeDocumentsFomento = (state: RootState) =>
  state.typeDocumentsFomentoState;

export const getGendersFomento = (state: RootState) => state.genderFomentoState;

export const getIndigenousReservation = (state: RootState) =>
  state.indigenousReservationState;

export const getIndigenousVillage = (state: RootState) =>
  state.indigenousVillageState;

export const getReceptionCenter = (state: RootState) =>
  state.receptionCenterState;

export const getEthnicGroupFomento = (state: RootState) =>
  state.ethnicGroupFomentoState;

export const getCapturingLayoffs = (state: RootState) =>
  state.capturingLayoffsState;

export const getDocumentation = (state: RootState) => state.documentationState;

export const getValidation = (state: RootState) => state.validationState;

export const getPostulationBusinessDays = (state: RootState) =>
  state.postulationBusinessDaysState;

export const getNavigation = (state: RootState) => state.navigationState;

export const getOffDays = (state: RootState) => state.offDaysState;

export const getPreValidation = (state: RootState) => state.preValidatorState;

export const getNeighborhood = (state: RootState) => state.neighborhoodState;
